import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { Highlights } from '../ui/Highlights';
import { PAGE_TOOLS } from '../../constants/pages';
import Link from 'next/link';

const ItemLine = ({ children }) => (
  <Typography
    component="h3"
    variant="h6"
    color="rgb(238, 238, 239)"
    gutterBottom
    sx={{ fontWeight: 500 }}
    align="left"
  >
    {children}
  </Typography>
);

export const PageMain = ({ t }) => (
  <>
    <Grid
      component="section"
      container
      spacing={4}
      sx={{ background: '#222b45', px: 4, py: 8 }}
    >
      <Grid item container alignItems="center" xs={12} md={6}>
        <Box>
          <Box marginBottom={2}>
            <Typography
              component="h1"
              color="white"
              sx={{
                fontWeight: 700,
                typography: { xs: 'h4', sm: 'h3', lg: 'h1' }
              }}
            >
              {t('part1')}
              <br />
              <Typography color="#8589ff" component="span" variant="inherit">
                {t('part2')}
              </Typography>{' '}
              {t('part3')}
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="#8b95a7"
              sx={{ fontWeight: 400 }}
            >
              {t('Fast, simple - everything for you')}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretch', sm: 'flex-start' }}
          >
            <Link href={PAGE_TOOLS} passHref>
              <Button variant="contained" color="primary" size="large">
                {t('Start using')}
              </Button>
            </Link>
          </Box>
        </Box>
      </Grid>
    </Grid>
    <Highlights t={t} />
    <Box sx={{ background: '#222b45', p: 4, mx: 'auto' }}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
          py: 2
        }}
        gutterBottom
        color="#8b95a7"
        align="center"
      >
        {t('About the service')}
      </Typography>
      <Typography
        fontWeight={700}
        variant="h3"
        gutterBottom
        align="center"
        color="rgb(238, 238, 239)"
        sx={{
          typography: { xs: 'h4', lg: 'h3' }
        }}
      >
        {t('If you are')}
      </Typography>
      <Typography variant="h6" component="p" color="#8b95a7" align="center">
        {t('No subscriptions, do not use, do not pay')}
      </Typography>
      <Box marginTop={3} display="flex" justifyContent="center">
        <Link href={PAGE_TOOLS} passHref>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            {t('Start')}
          </Button>
        </Link>
      </Box>
    </Box>
    <Box sx={{ background: '#26314f', p: 4 }}>
      <Typography
        fontWeight={700}
        variant="h3"
        gutterBottom
        align="center"
        color="rgb(238, 238, 239)"
        sx={{
          typography: { xs: 'h4', lg: 'h3' }
        }}
      >
        {t('block3.title')}
      </Typography>
      <Box sx={{ display: 'grid', gridGap: '12px' }}>
        <ItemLine>{t('block3.items.0')}</ItemLine>
        <ItemLine>{t('block3.items.1')}</ItemLine>
        <ItemLine>{t('block3.items.2')}</ItemLine>
        <ItemLine>{t('block3.items.3')}</ItemLine>
        <ItemLine>{t('block3.items.4')}</ItemLine>
        <ItemLine>{t('block3.items.5')}</ItemLine>
      </Box>
    </Box>
  </>
);
