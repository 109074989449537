import React from 'react';
import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { generateSeoData } from '../shared/utils/generateSeoData';
import { NAMESPACES } from '../constants/locales';
import { PAGES } from '../constants/pages';
import { Header } from '../shared/ui/Header';
import { Layout } from '../shared/ui/Layout';
import { Footer } from '../shared/ui/Footer';
import { PageMain } from '../shared/pages/PageMain';
import { LazyHydrateLayout } from '../shared/ui/LazyHydrateLayout';

const HOST_LANDINGS = process.env.NEXT_PUBLIC_LANDINGS_ORIGIN;
const CURRENT_PAGE = PAGES.PAGE_MAIN;
const NS = NAMESPACES.main;

const Page = ({ t, _nextI18Next }) => {
  const locale = _nextI18Next.initialLocale;

  const seoData = generateSeoData({
    title: t('meta.title'),
    description: t('meta.description'),
    host: HOST_LANDINGS,
    locale,
    path: CURRENT_PAGE,
    type: 'website'
  });

  return (
    <LazyHydrateLayout
      Header={<Header t={t} currentPage={CURRENT_PAGE} />}
      Content={
        <Layout seoData={seoData}>
          <PageMain t={t} />
        </Layout>
      }
      Footer={
        <Footer t={t} currentPage={CURRENT_PAGE} currentLocale={locale} />
      }
    />
  );
};

export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, [NS, NAMESPACES.common]))
  }
});

export default withTranslation(NS)(Page);
