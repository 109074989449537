import React from 'react';
import { Box, Avatar, Grid, Typography } from '@mui/material';

const highlightsData = [
  {
    title: 'highlights.0.title',
    subtitle: 'highlights.0.description',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    )
  },
  {
    title: 'highlights.1.title',
    subtitle: 'highlights.1.description',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    )
  },
  {
    title: 'highlights.2.title',
    subtitle: 'highlights.2.description',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    )
  }
];

export const Highlights = ({ t }) => (
  <Box sx={{ background: '#26314f', p: 4 }}>
    <Typography
      component="h2"
      sx={{
        textAlign: 'center',
        typography: { xs: 'h4', lg: 'h2' },
        color: 'rgb(238, 238, 239)'
      }}
    >
      {t('More features with')}
    </Typography>
    <Grid container spacing={2} sx={{ pt: 2 }}>
      {highlightsData.map((item, i) => (
        <Grid item xs={12} md={4} key={`highlights-${i.toString()}`}>
          <Box width={1} height="100%">
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box
                component={Avatar}
                width={60}
                height={60}
                marginBottom={2}
                bgcolor="rgba(0, 171, 85, 0.1)"
                color="rgb(0, 171, 85)"
              >
                {item.icon}
              </Box>
              <Typography
                component="h3"
                variant="h6"
                color="rgb(238, 238, 239)"
                gutterBottom
                sx={{ fontWeight: 500 }}
                align="center"
              >
                {t(item.title)}
              </Typography>
              <Typography align="center" color="rgb(174, 176, 180)">
                {t(item.subtitle)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);
